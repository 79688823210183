<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-4 position-relative">
        <i class="pt-1 pl-1 ri-search-line position-absolute z-1"></i>
        <input v-model="searchString" type="text" class="form-control pl-4 rounded-pill border-0 form-control-sm"
          :placeholder="$t('views.clients.searchField')">
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">{{$t('views.clients.clientsList')}}</h4>

            <div class="row">
              <div class="col-lg-12 mt-3">
                <table class="table table-hover" style="cursor: pointer;">
                  <thead>
                  <tr>
                    <th>ID</th>
                    <th>{{$t('views.clients.name')}}</th>
                    <th>{{$t('views.clients.surname')}}</th>
                    <th>{{$t('views.clients.phone')}}</th>
                    <th>
                      {{$t('views.clients.bonusPoints')}}
                      <i class="fas fa-hand-pointer"></i>
                    </th>
                    <th>{{$t('views.clients.createdDatetime')}}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in data" :key="item.id">
                    <td>{{item.id}}</td>
                    <td>{{item.first_name}}</td>
                    <td>{{item.last_name}}</td>
                    <td>{{item.phone_number}}</td>
                    <td @click="showAddBonusPopup(item.id)">{{item.userPoint.balance}}</td>
                    <td>{{item.created_at}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="paginator mt-3">
              <paginator
                  :pages="pagesCountPaginator"
                  :now="thisPage"
                  @setPage="setPage"
              ></paginator>
            </div>

          </div>
        </div>
      </div>


      <popup
          :closeButton="addBonusPopup.closeButton"
          :show="addBonusPopup.show"
          @closePopup="closeAddBonusPopup"
      >
        <div slot="header">{{$t('views.clients.addBonus')}}</div>
        <div slot="body">
          <div class="row">
            <div class="col-12">
              <form action="" @submit.prevent="submitAddBonus">
                <div class="form-group">
                  <label for="printerData_code">{{$t('views.clients.sum')}}</label>
                  <input
                      id="printerData_code"
                      v-model="sumPoint"
                      type="text"
                      class="form-control form-control-sm"
                      :class="{ 'is-invalid': submitted && $v.sumPoint.$error }"
                  />
                  <div v-if="submitted && $v.sumPoint.$error" class="invalid-feedback">
                    <span v-if="!$v.sumPoint.required">{{$t('validatePhrases.requiredField')}}</span>
                    <span v-if="!$v.sumPoint.minValue">
                      {{$t('validatePhrases.minValue')}}: 1
                    </span>
                    <span v-if="!$v.sumPoint.maxValue">
                      {{$t('validatePhrases.maxValue')}}: 5000
                    </span>
                  </div>
                </div>
                <div class="form-group">
                  <button class="btn btn-sm btn-success">{{$t('popularWords.add')}}</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </popup>

    </div>
  </Layout>
</template>


<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";


import { minValue, maxValue, required} from "vuelidate/lib/validators";
import axios from "axios";
import Paginator from "@/components/Paginator";
import Popup from "@/components/Popup";

/**
 * Rbac component
 */
export default {
  components: {
    Popup,
    Paginator,
    Layout,
    PageHeader
  },
  data() {
    return {
      addBonusPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
      },
      submitted: false,
      title: this.$t('menuitems.management.list.clients'),
      items: [
        {
          text: this.$t('menuitems.management.text'),
        },
        {
          text: this.$t('menuitems.management.list.clients'),
          active: true
        }
      ],
      data: [],
      searchString: '',
      sumPoint: 0,
      addBonusUserId: 0,
      searchStrTimeout: false,
      thisPage: 1, // Текущая страница
      pagesCountPaginator: 0, // Кол-во страниц
      pageSize: 25, // Сколько записей подгружать на 1 страницу
    };
  },
  validations: {
    sumPoint: { required, minValue: minValue(1), maxValue: maxValue(5000) }
  },
  computed: {

  },
  watch: {
    searchString: function(){
      clearTimeout(this.searchStrTimeout);

      this.searchStrTimeout = setTimeout(() => {
        this.getData();
      }, 2000);
    },
  },
  methods: {
    submitAddBonus(){
      this.submitted = true;
      // stop here if form is invalid
      this.$v.sumPoint.$touch();
      if(!this.$v.sumPoint.$invalid){
        this.$store.dispatch("startPreloader");
        let formData = new FormData();
        formData.append("sum", this.sumPoint);
        formData.append("user_id", this.addBonusUserId);
        axios
          .post(`/v1/users/set-copix-bonus`, formData)
          .then(resp => {
            if(resp.data.result == 'ok'){
              this.$store.dispatch("addNotification", {
                text: this.$t('popularWords.successfully'),
                time: 3,
                color: "success"
              });
              this.closeAddBonusPopup();
              this.getData();
            }
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
      }
    },
    closeAddBonusPopup(){
      this.sumPoint = 0;
      this.addBonusPopup.show = false;
    },
    showAddBonusPopup(userId){
      this.addBonusUserId = userId;
      this.addBonusPopup.show = true;
    },
    editUserShow(obj){
      this.editUser = obj;
      this.editUserPopup.show = true;
    },
    closeEditUserPopup(){
      this.editUserPopup.show = false;
    },
    getData() {
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("page", this.thisPage);
      formData.append("page_size", this.pageSize);
      formData.append("search_str", this.searchString);
      axios
          .post(`/v1/users/get-copix`, formData)
          .then(resp => {
            this.data = resp.data.data;
            this.pagesCountPaginator = resp.data.pages;
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    },
    setPage(val) {
      this.thisPage = val;
      this.getData();
    }
  },
  created() {
    this.getData();
  }
};
</script>
